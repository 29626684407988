<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <div>
    <v-col class="mx-1 my-2  ">
      <v-card>
        <v-row class="px-2 py-2">
          <v-spacer />
          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="3"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <Button
                  :text="true"
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </Button>
                <Button
                  :text="true"
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </Button>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="santralModel"
              :items="santralList"
              label="Santral"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="measureSelect"
              :items="measures"
              label=""
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            lg="2"
            xl="1"
            class="  py-0 my-0  mb-2"
          >
            <Button
              color="primary"
              @click="getData"
            >
              Filtrele
            </Button>
          </v-col>
        </v-row>

        <v-col
          cols="12"
        >
          <echart
            id="heatmapsHourlySS"
            class-name="widget-content"
            :height="height"
            width="100%"
            :chart-data="HeatmapData"
          />
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>

import * as Heatmap from '@/api/CombinerBox/Heatmap';
import moment from 'moment';
import i18n from '@/locale/index';
import Echart from '@/views/components/Echart/Chart.vue';
import { getStringboxDataList } from '@/api/Request/stringbox';
import helper from '@/api/helper';

export default {
  name: 'Dashboard',
  components: {
    Echart,
  },
  data: () => ({
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),

    menu: false,
    modal: false,
    menu2: false,
    mina: '',
    maxa: '',
    dialog: false,
    nowDate: new Date().toISOString().slice(0, 10),
    menu1: false,
    minDate: '',
    daterangetext: i18n.t('Tarih Seç'),
    selecteditemstext: '',
    mindate: '2010',
    all: i18n.t('All'),
    dateRules: [(v) => !!v || 'Date is required'],
    dates: [],
    measureList: [],
    santral_items: [],
    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    measureSelect: 'DCCurrent',
    componentKey: 1,
    title: '',
    santralModel: '',
    inverterList: [],
    height: '',
    HeatmapData: {},
    santralStringboxData: {},
  }),
  computed: {
    santralList() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralList = [];
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties

      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });

      // eslint-disable-next-line consistent-return
      return santralList;
    },
    santralData() {
      const { santralRawData } = this.$store.getters;
      if (helper.is_Empty(santralRawData)) return;
      const santralData = {};
      santralRawData.forEach((item) => {
        santralData[item.santralCode] = item;
      });
      // eslint-disable-next-line consistent-return
      return santralData;
    },
    measures() {
      const { stringboxMeasureList } = this.$store.getters;
      if (Object.keys(stringboxMeasureList).length > 0) {
        return stringboxMeasureList;
      }
      return [];
    },

    dateRangeText() {
      if (this.dates.length > 1) {
        const dateArr = this.dates;
        if (dateArr[0] > dateArr[1]) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[1]} ~ ${dateArr[0]}`;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[0]} ~ ${dateArr[1]}`;
        }
      } else {
        // eslint-disable-next-line prefer-destructuring, vue/no-side-effects-in-computed-properties
        this.selecteditemstext = this.dates[0];
      }

      return this.selecteditemstext;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    santralModel() {
      if (
        this.santralModel !== null
        && this.santralModel !== undefined
        && Object.keys(this.santralStringboxData).length > 0
      ) {
        this.getData();
      }
    },
  },
  created() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);
    const self = this;
    function myTimer1() {
      const { santralStringboxData } = self.$store.getters;

      if (santralStringboxData !== undefined && Object.keys(santralStringboxData).length > 0) {
        clearInterval(myVar1);
        self.santralStringboxData = santralStringboxData;
        self.santralModel = self.santralList[0].value;
      }
    }
  },
  mounted() {
  },

  methods: {

    getData() {
      this.title = `${moment()
        .add(-6, 'day')
        .format('YYYY-MM-DD')} / ${moment().format('YYYY-MM-DD')} ${i18n.t(
        this.measureSelect,
      )}`;

      const { santralStringboxData } = this;

      if (helper.is_Empty(santralStringboxData)) {
        return;
      }
      this.finishDate = this.startDate;
      const stringboxNameList = {};

      Object.keys(santralStringboxData[this.santralModel]).forEach((item) => {
        const k = santralStringboxData[this.santralModel][item].STRINGBOX;
        const v = santralStringboxData[this.santralModel][item].STRINGBOXNAME;
        stringboxNameList[k] = v;
      });

      const stringList = Object.keys(santralStringboxData[this.santralModel]);
      const stringboxCount = santralStringboxData[this.santralModel].length;
      this.height = '400px';
      if (stringboxCount < 11) {
        this.height = '400px';
      }
      if (stringboxCount > 10 && stringboxCount < 15) {
        this.height = '600px';
      }

      if (stringboxCount > 15 && stringboxCount < 20) {
        this.height = '800px';
      }

      if (stringboxCount > 20) {
        this.height = '900px';
      }
      // eslint-disable-next-line no-plusplus
      this.componentKey++;
      const formdata = {
        condiniton: {
          santral: this.santralData[this.santralModel].santral,
          startDate: this.startDate,
          finishDate: this.finishDate,
          measures: this.measureSelect,
          STRINGBOX: stringList,
          maxcount: 5000,
        },
      };
      const steptime = this.$store.getters.stepTime;

      let sMeasure;
      this.measures.forEach((v) => {
        if (this.measureSelect === v.value) {
          sMeasure = v;
        }
      });

      const tempdata = getStringboxDataList(formdata);
      tempdata.then((_raw) => {
        const temp = Heatmap.default.heatmapWeeklyHourMean(
          _raw,
          sMeasure,
          stringboxNameList,
          steptime,
        );
        temp.then((res) => {
          this.HeatmapData = res;
        });
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },

    onDateSelected(daterange) {
      // eslint-disable-next-line no-underscore-dangle
      this.finishDate = moment(daterange[1]._d).format('YYYY-MM-DD');
      // eslint-disable-next-line no-underscore-dangle
      this.startDate = moment(daterange[0]._d).format('YYYY-MM-DD');
      const alrt = this.select_filter();

      if (alrt !== undefined) {
        // eslint-disable-next-line no-alert
        alert(alrt);
      }
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t('Date Range');
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t('Date Range')} ${i18n.t(
        this.selectcolumn,
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        // eslint-disable-next-line prefer-destructuring
        this.finishDate = selectdate[0];
        // eslint-disable-next-line prefer-destructuring
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        // eslint-disable-next-line prefer-destructuring
        this.finishDate = selectdate[0];
        // eslint-disable-next-line prefer-destructuring
        this.startDate = selectdate[1];
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.finishDate = selectdate[1];
        // eslint-disable-next-line prefer-destructuring
        this.startDate = selectdate[0];
      }
    },
  },
};
</script>

<style scoped>
#heatmapsHourlypage {
  position: relative;
  height: 350px;
  margin: 1px;
  padding: 1px;
}

#textrotate {
  text-align: center;
}

#heatmapsHourly {
  height: 95%;
}
</style>
