<template>
  <v-chart
    :id="id"
    class="chart"
    :style="{height: height, width: width}"
    :option="chart"
    autoresize
  />
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  BarChart,
  LineChart,
  PieChart,
  GaugeChart,
  HeatmapChart,
} from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent,
  DataZoomComponent,
  VisualMapComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import VChart from 'vue-echarts';

use([
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  PieChart,
  GaugeChart,
  LabelLayout,
  HeatmapChart,
  UniversalTransition,
  CanvasRenderer,
  ToolboxComponent,
  DataZoomComponent,
  VisualMapComponent,
]);

export default {
  name: 'ECharts',
  components: {
    VChart,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    chartData: {},
    id: {
      type: String,
      default: 'chart',
    },
    width: {
      default: '100%',
    },
    height: {
      default: '300px',
    },
  },
  provide: {},
  data: () => ({
    chart: {
      title: {
        text: '',
      },
      tooltip: {},
      legend: {
        data: [],
      },
      xAxis: {
        data: [],
      },
      yAxis: {},
      series: [],
    },
  }),
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.chart = val;
      },
    },
  },
  mounted() {},
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    // this.chart.dispose();
    this.chart = null;
  },
};
</script>

<style scoped>
.chart {
  height: 50vh;
}

canvas {
  height: 50vh;
  width: 90%;
  display: block;
}
</style>
